<template>
    <header class="bg-white">
        <div class="flex items-start justify-center h-20 w-full">
            <div class="flex-1 self-center ml-2">
                <label for="toggle" class="flex items-center cursor-pointer">
                    <div class="text-2xl flex-initial flex-grow-0 mr-2" :class="showDark ? 'text-gray-400' : 'text-yellow-400'">
                        <i class="fas fa-sun"></i>
                    </div>
                    <!-- toggle -->
                    <div class="relative">
                        <!-- input -->
                        <input id="toggle" type="checkbox" class="sr-only" @click="setShowDark(!showDark)" />
                        <!-- line -->
                        <div class="line block bg-yellow-500 w-12 h-6 rounded-full"></div>
                        <!-- dot -->
                        <div class="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition"></div>
                    </div>
                    <!-- label -->
                    <div class="text-2xl ml-2" :class="showDark ? 'text-blue-200' : 'text-gray-400'">
                        <i class="fas fa-moon"></i>
                    </div>
                </label>
            </div>
            <LoginComponent />
        </div>
    </header>
</template>

<script>
import LoginComponent from "../LoginComponent.vue";
import { mapState, mapMutations, mapActions } from "vuex";
export default {
    components: {
        LoginComponent,
    },
    computed: {
        ...mapState(["showDark", "showMenu"]),
        ...mapState("userStore", ["user"]),
    },
    data() {
        return {
            portfolioShow: false,
        };
    },
    methods: {
        ...mapActions("userStore", ["logOutUser"]),
        ...mapMutations(["setShowDark", "setShowMenu"]),
        clickPortfolio() {
            this.portfolioShow = !this.portfolioShow;
        },
        logOut() {
            const self = this;
            self.logOutUser();
            self.$router.push({ name: "login" });
            location.reload();
        },
        // clickThemeChange() {
        //     this.setShowDark(!this.showDark);
        // }
    },
};
</script>

<style scoped lang="scss">
input:checked ~ .dot {
    transform: translateX(140%);
    background-color: theme("colors.white");
}
input:checked ~ .line {
    background-color: theme("colors.gray.900");
}
</style>
