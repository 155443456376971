<template>
    <div class="mr-5 flex-grow-0 self-center">
        <div class="relative">
            <div class="flex items-center justify-center">
                <div class="text-2xl relative">
                    <div class="-top-2 absolute right-3 w-5 h-5 bg-red-600 text-xs text-center align-middle text-white rounded-full">10</div>
                    <span class="cursor-pointer -top-4 right-0" @click="noticeShow = !noticeShow"><i class="fas fa-bell mr-5"></i></span>
                    <transition
                        enter-class="transform opacity-0 -translate-y-20 transition duration-300"
                        enter-to-class=" transform opacity-1 translate-y-0 transition duration-300"
                        leave-active-class="transform opacity-0 translate-x-40 transiton duration-500">
                        <div
                            v-if="noticeShow"
                            class="absolute md:right-0 -right-40 z-10 w-[600px]"
                            :class="showMenu ? 'bg-black rounded-lg p-2' : ''">
                            <div class="bg-white rounded-lg shadow-gray-md w-auto text-xs py-2 sm:mt-5 h-[500px] overflow-y-scroll flex-grow">
                                <div class="pl-2 py-3 border-b border-gray-50">
                                    <h2 class="text-xl text-center">通知</h2>
                                </div>
                                <Notification :datas="notificationDatas" />
                            </div>
                        </div>
                    </transition>
                </div>
                <span class="cursor-pointer text-2xl mr-5 relative" @click="$router.push({ name: 'chats' })">
                    <i class="fas fa-comment"></i>
                    <div
                        v-if="realtimeUnReadMessageCount > 0"
                        class="-top-2 absolute -right-2 w-5 h-5 bg-red-600 text-xs text-center align-middle text-white rounded-full">
                        {{ realtimeUnReadMessageCount }}
                    </div>
                </span>
                <!-- <span class="cursor-pointer text-2xl mr-5"
                      @click="$router.push({name:'chats'})"><i class="fas fa-comment"></i></span> -->
                <div class="flex flex-col sm:not-sr-only not-sr-only">
                    <div class="mr-2 text-gray-300 font-light">hello,</div>
                    <div class="mr-2">{{ user.comment }}</div>
                </div>
                <div @click="clickProfile()">
                    <Avatar :backgroundImg="checkUserAvatar" :size="['w-14 h-14']" :isShowLight="true" />
                </div>
            </div>
            <transition
                enter-class="transform opacity-0 -translate-y-20 transition duration-300"
                enter-to-class=" transform opacity-1 translate-y-0 transition duration-300"
                leave-active-class="transform opacity-0 translate-x-40 transiton duration-500">
                <nav v-if="profileShow" class="absolute right-0 z-10">
                    <ul class="bg-white rounded-lg shadow-2xl mt-5 sm:py-5 pb-3 px-5">
                        <!-- <li class="mb-2 py-1 cursor-pointer hover:text-yellow-500 transition duration-100">
                            <span class="mr-2"><i class="fas fa-cog"></i></span>帳號設定
                        </li>
                        <li class="mb-2 py-1 cursor-pointer hover:text-yellow-500 transition duration-100">
                            <span class="mr-2"><i class="fas fa-bell"></i></span>通知中心
                        </li> -->
                        <li class="mb-2 py-2 cursor-pointer hover:text-red-600 transition duration-300 text-lg" @click="logOut()">
                            <span class="mr-2"><i class="fas fa-sign-out-alt"></i></span>登出
                        </li>
                    </ul>
                </nav>
            </transition>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
// 頭像組件
import Avatar from "../components/Avatar.vue";
// 通知組件
import Notification from "../components/Notification.vue";
export default {
    components: {
        Avatar,
        Notification,
    },
    computed: {
        ...mapState(["showMenu"]),
        ...mapState("userStore", ["isAuth", "user"]),
        ...mapGetters("chatStore", ["realtimeUnReadMessageCount"]),
        checkUserAvatar() {
            if (this.user.avatar === undefined) {
                return "/img/avatar/default.jpeg";
            } else {
                return this.user.avatar;
            }
        },
    },
    data() {
        return {
            // 登入時點擊大頭照 呈現操作功能
            profileShow: false,
            // 判斷是否呈現通知區塊
            noticeShow: false,
            // 通知假資料
            notificationDatas: {
                systemNotice: {
                    title: "系統通知",
                    datas: [
                        {
                            name: "Json",
                            time: this.$moment(new Date()).startOf("time").add(-100, "minutes").fromNow(),
                            type: "inviation",
                            message: null,
                            avatar: "../../img/avatar/dangerNoticeAvatar.png",
                        },
                        {
                            name: "Bob",
                            time: this.$moment(new Date()).startOf("time").add(-10, "minutes").fromNow(),
                            type: "change",
                            message: null,
                            avatar: "../../img/avatar/dangerNoticeAvatar.png",
                        },
                        {
                            name: "小米",
                            time: null,
                            type: "cancel",
                            message: "抱歉～家裡臨時有事要處理 ><",
                            avatar: "../../img/avatar/avatar3.jpg",
                        },
                        {
                            name: "阿傑",
                            time: this.$moment(new Date()).startOf("time").add(-12, "minutes").fromNow(),
                            type: "renew",
                            message: null,
                            avatar: "../../img/avatar/default.jpeg",
                        },
                    ],
                },
                remaining: {
                    title: "即將到來的約會",
                    datas: [
                        {
                            name: "妮可",
                            time: this.$moment(new Date()).format("YYYY/MM/DD HH:mm:ss"),
                            type: "remaining",
                            remaining: this.$moment(new Date()).hour(-22).format("HH:mm:ss"),
                            message: null,
                            avatar: "../../img/avatar/avatar2.jpg",
                        },
                    ],
                },
            },
        };
    },
    methods: {
        ...mapActions("userStore", ["logOutUser"]),
        ...mapMutations("userStore", ["setIsAuth"]),
        // 點擊大頭照後事件
        clickProfile() {
            this.profileShow = !this.profileShow;
        },
        // 登出
        logOut() {
            this.logOutUser();
            this.$router.push({ name: "login" });
        },
    },
};
</script>
