<template>
    <div>
        <div class="flex px-5 border-b shadow-xl">
            <div
                v-for="(notice, noticeKey) in datas"
                :key="noticeKey"
                class="flex-grow text-center text-base py-2"
                :class="[noticeKey === currentTab ? 'border-b-4 border-orange-500' : '']">
                <span class="cursor-pointer relative" @click="changeTab(noticeKey)">
                    {{ notice.title }}
                    <span class="absolute -right-2 top-1 w-2 h-2 bg-red-500 rounded-full"></span>
                </span>
            </div>
        </div>
        <ul>
            <li
                v-for="(item, noticeIndex) in datas[currentTab].datas"
                :key="noticeIndex"
                class="flex flex-wrap border-b border-gray-50 items-center py-3 px-2"
                :class="noticeIndex === 0 ? 'bg-yellow-100' : ''">
                <span class="mr-2">
                    <Avatar :size="['w-10', 'h-10']" :backgroundImg="item.avatar" />
                </span>
                <!-- <span v-else class="w-10 h-10 rounded-full bg-red-200 mr-2 shadow-gray-sm flex items-center justify-center">
                    <i class="fas fa-exclamation text-red-600"></i>
                </span> -->
                <div class="sm:text-sm text-xs">
                    <h4 v-if="currentTab === 'remaining'" v-html="showMessage(item)"></h4>
                    <h4 v-else>
                        <strong class="mr-2">{{ item.name }}</strong
                        >{{ showMessage(item) }}
                    </h4>
                    <h5 v-if="item.time !== null" class="text-gray-300">{{ item.time }}<span v-if="currentTab !== 'remaining'"></span></h5>
                    <h5 v-else class="text-gray-300">{{ item.message }}</h5>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
// 頭像組件
import Avatar from "../components/Avatar.vue";
/**
 * remaining: 即將到來
 * cancel: 取消約會
 * inviation: 邀請約會
 * renew: 續約約會
 * change: 更改約會內容
 */
const type = ["remaining", "cancel", "inviation", "renew", "change"];
export default {
    components: {
        Avatar,
    },
    props: {
        datas: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    data() {
        return {
            type: type,
            titleType: ["systemNotice", "remaining"],
            currentTab: "systemNotice",
        };
    },
    methods: {
        // 判斷data 中 type 呈現內容
        showMessage(data) {
            switch (data.type) {
                case "remaining":
                    return `<strong>與${data.name}的約會即將在</strong><span class="text-red-600 mx-2 font-bold">${data.remaining}</span>後開始`;
                case "cancel":
                    return "已取消與您的約會。";
                case "inviation":
                    return "向您發送了約會邀請，請儘快與他聯繫！";
                case "renew":
                    return "向您提出續約邀請，請儘速確認！";
                case "change":
                    return "已修改了約會內容，請儘速確認！";
            }
        },
        changeTab(val) {
            this.currentTab = val;
        },
    },
};
</script>
