<template>
    <div>
        <button class="bg-blue-500 text-white w-1/3 py-2 sr-only" @click.prevent="setShowMenu(!showMenu)">x</button>
        <div class="flex justify-center desktopShow">
            <div class="self-center mt-10 text-white dark:text-blue-100">
                <img class="w-full px-5" src="/img/logos/logo_type3.svg" alt="" />
                <!-- <h1 class="PattayaFont text-3xl">CityBanana<span class="ml-2 text-base">後台管理系統</span></h1> -->
            </div>
        </div>
        <ul class="list-none ml-3 mt-10">
            <!-- <li class="mt-5">
                <span @click="clickMenu('dashboard')"
                      class="transition duration-500 py-2 px-5 cursor-pointer"
                      :class="currentMenu === 'dashboard' ? 'bg-blue-500 rounded-full text-white': ''">
                    首頁
                </span>
            </li> -->
            <li v-for="(item, index) in menus" :key="index" class="mt-5">
                <!-- 判斷當點擊上層路由時 不執行上層路由導頁 只開啟下拉選單 -->
                <span
                    v-if="item.meta.custom_route_name === undefined"
                    class="transition duration-200 py-2 px-5 cursor-pointer"
                    :class="currentMenu === item.name ? 'text-yellow-300' : ''"
                    @click="clickMenu(item.name)">
                    <i :class="item.icon" class="mr-2"></i>
                    {{ item.meta.text }}
                    <i
                        v-if="!$isEmpty(item.children)"
                        class="ni ni-bold-right transition duration-200 ease-linear align-middle mb-1"
                        :class="activeMenu[item.name] ? 'rotate-0 transform' : 'rotate-90 transform'"></i>
                </span>
                <!-- 判斷點擊上層路由時 需導頁到指定的頁面 -->
                <span
                    v-else
                    class="transition duration-200 py-2 px-5 cursor-pointer"
                    :class="currentMenu === item.meta.custom_route_name ? 'text-yellow-300' : ''"
                    @click="clickMenu(item.meta.custom_route_name, true)">
                    <i :class="item.icon" class="mr-2"></i>
                    {{ item.meta.text }}
                </span>
                <ul v-if="!$isEmpty(item.children)" class="list-none ml-10">
                    <MenuItem
                        v-for="(item2, index2) in item.children"
                        :key="index2"
                        :class="
                            activeMenu[item.name]
                                ? 'opacity-1 translate-x-0 transform duration-500 ease-in-out transition'
                                : '-translate-x-1/2 sr-only opacity-0 transition transform duration-700 ease-in-out'
                        "
                        :item="item2"
                        @onActiveMenuParent="activeMenuClass" />
                </ul>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
//子選單樣板
import MenuItem from "../../components/MenuItem.vue";
export default {
    components: {
        MenuItem,
    },
    props: {
        menus: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    computed: {
        // 用來判斷是否呈現按鈕 在手機版時
        ...mapState(["showMenu"]),
    },
    data() {
        return {
            // 用來判斷是否顯示子選單內容，以及父選單箭頭方向
            activeMenu: {},
            // 判斷當前選中的父選單
            currentMenu: null,
        };
    },
    methods: {
        // 開關手機版選單事件
        ...mapMutations(["setShowMenu"]),
        /**
         * 子選單被選中時觸發
         * @param { type String(字串) } val 子選單的上層路由key
         */
        activeMenuClass(val) {
            // 觸發點擊父選單方法
            this.clickMenu(val);
            // 更改當前選中的父選單
            this.currentMenu = val;
            // 新增用來判斷父選單是否顯示子選單的key與更改箭頭方向
            this.$set(this.activeMenu, val, true);
        },
        /**
         * 點擊父選單時事件
         * @param { type String(字串) } val 父選單key
         * @param { type String(字串) } isCustomRoute 判斷是自定義的導向頁面時觸發導頁事件
         */
        clickMenu(val, isCustomRoute = false) {
            // 判斷選中的父選單key不等於當前父選單 key時 關閉子選單與更改箭頭方向
            if (val !== this.currentMenu) {
                this.$set(this.activeMenu, this.currentMenu, false);
            }
            // 判斷選中父選單是否有選中的key
            // eslint-disable-next-line no-prototype-builtins
            if (!this.activeMenu.hasOwnProperty(val)) {
                // 如果沒有 key 則加入 並且給值 true (用來判斷是否呈現子選單與更改箭頭方向)
                this.$set(this.activeMenu, val, true);
            } else {
                // 如果有 key 且被點擊時 則修改當前 boolean值
                this.activeMenu[val] = !this.activeMenu[val];
            }
            // 當有指定客製化路由時 直接進行導頁
            if (isCustomRoute) {
                this.$router.push({ name: val });
            }
            this.currentMenu = val;
            // // 判斷如果是以下路由 執行對應導頁 以及 當前按鈕更改
            // const filterRoutes = ["dashboard"];
            // // 設定當前選中選單
            // if (filterRoutes.includes(val)) {
            //     // 判斷是否此路由有需要攜帶參數
            //     // eslint-disable-next-line no-prototype-builtins
            //     if (this.$route.meta.hasOwnProperty("params")) {
            //         this.$router.push({ name: val, params: this.$route.meta.params }).catch((err) => err);
            //     } else {
            //         this.$router.push({ name: val }).catch((err) => err);
            //     }
            //     // 因為預設第一次進入網站時 無需觸發此事件 因此做一個判斷
            //     if (setShowMenuWork) {
            //         // 手機版時 選單被點擊後 關閉選單事件
            //         this.setShowMenu(!this.showMenu);
            //     }
            // }
        },
    },
    created() {
        this.$nextTick(() => {
            this.currentMenu = this.$route.name;
        });
    },
};
</script>
